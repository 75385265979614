import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch} from 'react-redux'
import Search from 'views/components/Search';
import { handleLoadToasts } from "utils/eventHandling";
import { FormControl, InputGroup } from "react-bootstrap";


const MfaReset = props => {
  const dispatch = useDispatch();
  const loadStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.usersLoadStatus);    
  const [email, setEmail] = useState("");
  const [emailValidated, setEmailValidated] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [searchResultsLimit, setSearchResultsLimit] = useState(5);

  useEffect(() => {
    if(loadStatus === "success" || loadStatus === "failed"){
      handleLoadToasts(loadStatus, props.setShowSuccessToast, props.setShowFailureToast);
    }
  }, [loadStatus]);

  const resetMfa = e => {
    e.preventDefault();
    // const user = users.find(user => user.email === email);
    // if(user){
    //   dispatch({
    //     type: "RESET_USER_MFA",
    //     organizationId: props.property.organization,
    //     propertyId: props.property._id,
    //     userId: user._id
    //   })
    //   setIsValidEmail(true);
    // } else {
    //   setEmailValidated(true);
    //   setIsValidEmail(false);
    // }
    dispatch({
      type: "RESET_USER_MFA",
      organizationId: props.property.organization,
      propertyId: props.property._id,
      email: email
    })
  }

  return (
    <Form noValidate validated={emailValidated} className="w-md-60 mb-5 mb-lg-0">
      <Form.Group>
        <Form.Label>User’s Email Address</Form.Label>
        <Form.Text className="subtext">
          Reset the second factor of authentication of your users.
        </Form.Text>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className={`${isValidEmail ? 'valid' : 'invalid'}`}>
              <i className="fa fa-search"/>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            data-testid="mfa-reset-email"
            placeholder="Search Users"
            value={email}
            onChange={(e) => {setEmail(e.target.value)}}
            className={`${isValidEmail ? 'valid' : 'invalid'}`}
          />
        </InputGroup>
        <Form.Control.Feedback id="invalid-reset-email-feedback" className={`${isValidEmail ? 'd-none' : 'd-block'}`} type="invalid">
          Cannot find user with that email
        </Form.Control.Feedback>
      </Form.Group>
      <Button data-testid="mfa-reset-button" type="submit" onClick={resetMfa} >
        Reset
      </Button>
    </Form>
  )
}

export default MfaReset;
